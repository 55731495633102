<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Parent Folders</h1>
      <div class="btns-container">
        <VasionButton
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="handleNewClick()"
        >
          New Parent Folder
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div v-if="folders === true">
        <!-- Show white space while waiting for data to load -->
      </div>
      <div v-else-if="folderList.length > 0 && tableData.Columns" class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="tableData.Columns.Values"
          :tableRows="tableData.Rows.Values"
          :hideColumns="hiddenColumns"
          :filterByColumnDefault="1"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          :ellipsisButtonConfigFiltered="ellipsisButtonConfigFiltered"
          :ellipsisFilterCondition="ellipsisFilterCondition"
          :supportSorting="true"
          @vasion-column-sort="sortColumns"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
      </div>
      <div v-else class="no-items-div">
        <div class="vasion-page-subheader instructional-message-div">
          No data to show.
        </div>
        <div class="button-div">
          <VasionButton :isFullWidth="true" :classProp="'primary'" @vasionButtonClicked="handleNewClick()">
            <span class="vasion-button-plus"> + </span> New Parent Folder
          </VasionButton>
        </div>
      </div>
    </div>
    <VasionGeneralModal
      id="vasion-confirmation-div"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="deleteMessage"
      :sync="showDeleteDialog"
      @confirmButtonClick="deleteFolder()"
      @noButtonClick="showDeleteDialog = !showDeleteDialog"
    />

    <VasionGeneralModal
      id="vasion-cannot-delete-div"
      :rejectButtonText="'OK'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="'This folder has contents.  It cannot be deleted.'"
      :sync="showCantDeleteDialog"
      :showConfirmButton="false"
      @noButtonClick="showCantDeleteDialog = !showCantDeleteDialog"
    />

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

    <VasionSnackbar
      id="save-snack"
      :showSnackbarBool.sync="showSaveSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="saveSnackSubtitle"
      :snackbarTitle="saveSnackTitle"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { tableSorter } from '../../../store/helperModules/common.module';

export default {
  name: 'ParentFolders',
  components: {
      Loading,
  },
  props: {
    saveSnackShowBool: {
      type: Boolean,
      required: false,
      default: false,
    },
    saveSnackSubtitle: {
      type: String,
      required: false,
      default: '',
    },
    saveSnackTitle: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: function () {
    return {
      columns: {
        Values: [
          '_FolderID_',
          '_HasChildren_',
          '_VasionIcon_',
          'Folder Name',
          'Drive Type',
          'Contains PII Data',
          'Read Only',
          'Encrypted',
          '_VasionEllipsisButton_',
          '_FolderType_',
        ],
        ValueType: 'String',
      },
      deleteFolderId: 0,
      deleteMessage: '',
      ellipsisFilterCondition: (dataRow) => {
        let folderTypeIndex = 9
        let physicalFolderTypesWorkflow = 2
        if (dataRow.Values.length >= 10) {
          return dataRow.Values[folderTypeIndex] === physicalFolderTypesWorkflow
        }
        return false
      },
      folders: true,
      hiddenColumns: ['_FolderID_', '_HasChildren_', '_FolderType_'],
      isLoading: false,
      loaderBackgroundColor: '#ece7ec',
      loaderColor: '#ff4702',
      localFolderList: null,
      showCantDeleteDialog: false,
      showDeleteDialog: false,
      showSaveSnackbar: false,
      showSnackbar: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      sortTableAscending: false,
      sortTableBy: '',
      tableData: {},
    }
  },
  computed: {
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    ellipsisButtonConfigFiltered() {
      let filteredObj = cloneDeep(this.ellipsisButtonConfig)
      filteredObj.buttons = filteredObj.buttons.filter(element => !(element.name === "Edit Folder" || element.name === "Delete Folder"))
      return filteredObj
    },
    folderList() { return this.$store.state.vault.vaultList },
  },
  async created() {
      this.isLoading = true
      await Promise.all([
        this.$store.dispatch('vault/getVaultList'),
        this.$store.dispatch('security/setEllipsisButtonConfig', 'parent-folder'),
      ])
      this.localFolderList = this.folderList
      this.folders = false
      await this.refreshTable()
      setTimeout(() => {
        this.showSaveSnackbar = this.saveSnackShowBool
      }, 500);      
  },
  methods: {
    async deleteFolder() {
      const response = await this.$store.dispatch('vault/deleteVault', this.deleteFolderId)
      this.showDeleteDialog = false
      this.snackbarTitle = response.Value === 'True' ? 'Folder Deleted Successfully' : 'Error Deleting Folder'
      this.snackbarSubTitle = response.Value === 'True' ? 'The Folder has been removed' : `Error: ${response.ResultMessage}`
      this.snackbarImage = response.Value === 'True'
      this.showSnackbar = true;
      await this.$store.dispatch('vault/getVaultList')
      this.localFolderList = this.folderList
      this.isLoading = true
      await this.refreshTable()
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [id, hasContents, , name] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-folder-button':
            this.handleEditClick(id)
            break;
          case 'refresh-for-search-button':
            this.refreshForSearch(id)
            break
          case 'delete-folder-button':
            if (hasContents) {
              this.showCantDeleteDialog = true
            }
            else {
              this.deleteMessage = `Are you sure you want to delete "${name}"?`
              this.deleteFolderId = id
              this.showDeleteDialog = true
            }
            break;
          default:
            break;
        }
      }
    },
    handleEditClick(id) { this.$router.push({ name: 'TheParentFolder', params: { folderId: id } }) },
    handleNewClick() { this.$router.push({ name: 'TheParentFolder', params: { folderId: 0 } }) },
    async refreshForSearch(id) {
      const response = await this.$store.dispatch('search/updateFolderSearchIndex', id)

      this.snackbarTitle = response.ResultStatus > 0 ? 'Folder Refreshed' : 'Error Refreshing Folder'
      this.snackbarSubTitle = response.ResultStatus > 0 ? 'The Folder has been refreshed for searching' : 'Could not refresh the Folder for searching'
      this.snackbarImage = response.ResultStatus > 0
      this.showSnackbar = true
    },
    async refreshTable() {
      const data = {
          folderList: this.folderList,
          columns: this.columns,
      }
      this.tableData = await this.$store.dispatch('admin/setTableData', data)
      this.isLoading = false
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      this.handleEditClick(data.Values[0])
    },
    async sortColumns(options) {
      this.isLoading = true
      this.sortTableAscending = options.ascending
      switch (options.columnName) {
        case 'Folder Name':
          this.sortTableBy = 'vaultName'
          break
        case 'Drive Type':
          this.sortTableBy = 'storageType'
          break
        case 'Contains PII Data':
          this.sortTableBy = 'piiData'
          break
        case 'Read Only':
          this.sortTableBy = 'readOnly'
          break
        case 'Encrypted':
          this.sortTableBy = 'encrypted'
          break
        default:
          break
      }
      this.localFolderList.sort(tableSorter(this.sortTableBy, this.sortTableAscending))
      await this.refreshTable()
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-div {
  margin-top: 3px;
  height: calc(100vh - 224px);
  overflow: auto;
}
</style>
